.main_layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(29, 29, 29);
}

.content {
    padding-top: 100px;
    padding-bottom: 100px;
}