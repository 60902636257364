.docscontent {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 60%;
    margin-left: 20%;
    //padding-bottom: 100px;
    padding-top: 10vh;
    border-right: 1px dashed rgb(162, 162, 162);
    border-left: 1px dashed rgb(162, 162, 162);
    
    
}

.title_area {
    width: 100%;
    text-align: center;
    justify-content: center;
    padding-bottom: 20px;
    //padding-left: 10%;
}

.title_area_title{
    justify-content: center;
    
}

.title_area_subtitle{
    //font: inherit;
    padding-top: 10px;
    font-style: italic;
    
    font-size: 20px;
    
}


.section_area{
    width: 100%;
    //background-color: rgb(86, 223, 104);
    text-align: left;
    justify-content: center;
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
    border-bottom: 1px dashed rgb(162, 162, 162)
}

.section_area_title{
    font-size: 30px;
    padding-bottom: 50px;
    text-align: center;
}



.article{
    width: 100%;
    //background-color: rgb(86, 88, 223);
    text-align: left;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgb(162, 162, 162, 0.4);
}

.article>h1{
    font-weight:500;
    //font-style: italic;
    text-decoration:underline;
    font-size: 22px;
    padding-bottom: 20px;
}

.article>p{
    font-family: inherit;
    font-style: normal;
    font-size: 16px;
    padding-bottom: 20px;
}

.article a, .article a:visited{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.article a:hover{
    color: rgb(217,205,237);
}

.keyword{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.indented_area {
    padding-left: 30px;
    padding-bottom: 20px;
}

.indented_area>h1 {
    font-weight:bold;
    font-size: 16px;
    padding-bottom: 0px;
}

.indented_area p {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}

.indented_area li {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
}

.indented_area p {
    padding-bottom: 16px;

}
.image_area {
    padding-bottom: 40px;
}

.image_area>img {
    display: block;
    max-height: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    max-width: 90%;
    //border-radius: 100px;
}

.image_area_description {
    font-size: 12px;
    font-weight: light;
    font-style: italic;
    text-align: right;
}

.tip_area {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
    font-size: 14px;
    background-color: rgba(192, 192, 192, 0.329);
    border-radius: 5px;
    border-color: black;
    //border-style: dashed;
    border-width: 2px;
    padding: 10px
}
