.main_content {
    //background-color: #ffffff;//#FFE644;
    //padding: 5rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(210, 203, 185);
}

.promo_image {
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
    
}

.promo_image>img{
    width: 70vw;
    max-width: 1000px;
}

.title {
    width: 50%;
    max-width: 400px;
    justify-content: left;
    font-size: xx-large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.description {
    width: 50%;
    max-width: 400px;
    justify-content: left;
    text-align: justify;
    font-size: medium;
    padding-bottom: 30px;
    padding-top: 30px;
}

.feature_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.download_message {
    font-size: 15px;
    line-height: 20px;
    // margin-top: 10px;
    text-align: center;
    margin-top:20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
    font-weight:lighter;
}

.video {
    padding-top: 50px;
    width: 70vw;
    max-width: 1000px;
}

.download_message a, .download_message a:visited {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.download_message a:hover{
    cursor: pointer;
}


.btn_download_area {
    
    text-align: center;
}

button {
    width: 140px;
    height: 40px;
    border: 1px solid #4b4b4b;
    background-image: url('./../../assets/images/colour_stripe.png');
    background-size: 100%;
    background-repeat: none;
    margin: 20px;
    //margin-right: 20px;
    &:hover 
        {
        cursor: pointer;}
}
.btn_download_content {
    font-size: 16px;
    background-color: antiquewhite;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    &:hover 
        {
            background-color: rgb(193, 95, 119);}
}



@media only screen and (max-width: 1024px) {

    
    .promo_image>img{
        width: 90vw;

    }
    
    .title {
        width: 90%;
  
    }
    
    .description {
        width: 90%;

    }
    
    .feature_grid {
        width: 90%;
    }

    .video {
        padding-top: 50px;
        width: 90vw;
    }
    
}