.main_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(210, 203, 185);
}

.collections_area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: top;
    flex-wrap: wrap;
}

.collection_container{
    display: flex;
    flex-direction: column;
    padding-left:40px;
    padding-right: 40px;
    padding-bottom: 40px;
    width: 40%;
    max-width: 400px;
    min-width: 340px;
}

.collection_title{
    max-width: 400px;
    justify-content: left;
    font-size: xx-large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    
}

.collection_image {
    width: 100%;
    padding-top:10px;
     padding-bottom:10px;
}

.collection_image img {
    width: 100%;
}


.collection_description {
    max-width: 400px;
    justify-content: left;
    text-align: justify;
    font-size: medium;
    padding-bottom: 30px;
}

.buy_link {
    margin-left: auto;
    padding-bottom: 3px;
    margin-top: auto;
}

.stats {
    margin-left: auto;
    font-size: small;
    font-weight: lighter;
}

@media only screen and (max-width: 1024px) {

    
    .collection_container{
        width: 90%;
    }
    
}


