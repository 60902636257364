.feature_box {
    display:flex;
    flex-direction: row;
    align-items: top;
    justify-content: inherit;
    padding: 20px;
}

.feature_box>img {
    height:20px;
    object-fit: contain;
    padding-right: 10px;
}

.feature_text {
    display:flex;
    width: 250px;
    flex-direction: column;
}
.feature_title {
    font-size: x-large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.feature_description {
    
    font-size: medium;
}

@media only screen and (max-width: 1024px) {

    
    .feature_text {
        width: 90%;
    }
    
}