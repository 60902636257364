.footer {
    width: 100%;
    height: 100px;
    border-top: 1px dashed rgb(210, 203, 185);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    margin-top: auto;
    font-size: 12px;
    color: rgb(210, 203, 185);
    background-color: rgb(29, 29, 29);
  }
  
  .logo {
    width: 70px;
  }

  .copyright {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 16px;
  }
  
.social_section {
    display:flex;
    flex-direction: row;
    align-items: center;
    width: 70px;
    justify-content: space-between;
    //margin-left: auto;
    //margin-right: auto;
}

.social_section img {
    width: 28px;
}

.footer_twitter {
    cursor: pointer;
}

.footer_discord {
    cursor: pointer;
}


@media only screen and (max-width: 1099px) {

}