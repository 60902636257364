

.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./../../../assets/images/colour_stripe.png');
    background-repeat:repeat-x;
    background-color: rgb(29, 29, 29);
  }

  .linkarea {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        //border-bottom: 5px solid rgb(210, 203, 185);

  }

  .textlinks {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-right: 130px;
    
  }

  .textlinks>a, .textlinks>a:visited {
    background-color: antiquewhite;
    color: rgb(29, 29, 29);
  }
  .textlinks>a:hover{
    cursor: pointer;
    background-color: rgb(193, 95, 119);
  }

  a, a:visited {
    color: rgb(210, 203, 185);
  }

  a:hover {
    cursor: pointer;
    color: rgb(193, 95, 119);
  }

  .logo {
    height: 80%;
    width: 100px;
    justify-self: flex-start;
    margin-left: 30px
  }

  .logo img {
    height: 100%;
  }

  .link {
    text-decoration: none;
    font-size: 12pt;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 1024px) {

    .navMenu{
        display: grid;
        position: absolute;
        height: 100vh;
        width: 100vw;
        left: 0px;
        top: 80px;
        background-color: antiquewhite;
        align-items: center;
        justify-content: center;
    }

    .linkarea {
      justify-content: flex-start;

    }

    .logo {
      margin-right: auto;
    }

    .textlinks {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      
    }
  
    .textlinks>a, .textlinks>a:visited {
      font-size: xx-large;
      padding-top: 50px;
      background-color: antiquewhite;
      color: rgb(29, 29, 29);
    }
    .textlinks>a:hover{
      cursor: pointer;
      background-color: rgb(193, 95, 119);
    }

}