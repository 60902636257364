.docsnav {

  position: fixed;
    padding-top: 2vh;
    width: 20%;
    height: 100%;
    
    
    //padding-top: 100px;
}

.docsnav_logo {
    //background-color:azure;
    padding-top: 40px;
    padding-bottom: 60px;
}

.docsnav_logo>img {
    display: block;
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
}

.docsnav_link_area{
    padding-left: 20px;
}

.docsnav_link_group{
    padding-bottom: 10px;
}

.docsnav_link {
    cursor: pointer; 
}

.docsnav_link>a {
    color:rgb(210, 203, 185);
    font-size: 16px;
    &:hover{
        color: rgb(186, 119, 155);
    }
}

.docsnav_sublink {
    cursor: pointer; 
    padding-left: 20px;
}
.docsnav_sublink>a {
    color:rgb(210, 203, 185);
    font-size: 14px;
    font-weight: lighter;
    &:hover{
        color: rgb(186, 119, 155);
    }
}

.docsnav_back {
    cursor: pointer;
    padding-top: 40px;
    padding-left: 20px;
}

.docsnav_back>a {
    color:darkgray;
    font-size: 16px;
    &:hover{
        color: rgb(230, 230, 230);
    }
}