.special_title {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: inherit;
}

.special_title>img {
    height:20px;
    object-fit: contain;
    padding-right: 10px;
}

.special_title_text {
    font-size: xx-large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}