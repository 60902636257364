.main_content {
    //background-color: #ffffff;//#FFE644;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(210, 203, 185);
}

.main_image {
    width: 50%;
    max-width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
    //background-image: url('./../../assets/images/chump.gif');
    //background-repeat: repeat-x;
    //background-size: 150px;
    //height: 150px;
}

.banner_image {
    width: 50%;
    max-width: 400px;
    //margin-top: 30px;
    margin-bottom: 30px;
    //background-image: url('./../../assets/images/chump.gif');
    //background-repeat: repeat-x;
    //background-size: 150px;
    //height: 150px;
}

.main_image>img {
    width: 100%
}

.banner_image>img {
    width: 100%
}

.title {
    width: 50%;
    max-width: 400px;
}

.description {
    width: 50%;
    max-width: 400px;
    justify-content: left;
    text-align: justify;
    font-size: medium;
    padding-bottom: 30px;
}

.description a, .description a:visited {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    //color: 
}

@media only screen and (max-width: 1024px) {

    .main_image {
        width: 90%;
        max-width: 400px;

    }
    
    .banner_image {
        width: 90%;
        max-width: 400px;

    }

    .title {
        width: 90%;
        max-width: 400px;
    }

    .description {
        width: 90%;
        max-width: 400px;

    }
}


