* {
    margin: 0;
    box-sizing: border-box;
}

a {
	text-decoration: none;
}

.on-hover:hover {
    cursor: pointer;
}

.white {
    color: white;
}

.black {
    color: black;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items:flex-end
}

.align-content-center {
    align-content: center;
}

.f {
    align-content: space-between;
}

.align-content-center {
    align-content:center
}

.align-content-start {
    align-content:flex-start;
}

.flex-flow-wrap {
    flex-flow: wrap;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-around {
    justify-content: space-around;
}

.font-900 {
    font-weight: 900 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-10 {
    font-size: 10px !important;
}
.mt-96 {
    margin-top: 96px;
}

.mt-56 {
    margin-top: 56px;
}

.mt-48 {
    margin-top: 48px !important;
}

.mt-40 {
    margin-top: 40px;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-0 {
    margin-top: 0;
}

.ml-96 {
    margin-left: 96px;
}

.ml-56 {
    margin-left: 56px;
}

.ml-48 {
    margin-left: 48px !important;
}

.ml-40 {
    margin-left: 40px;
}

.ml-32 {
    margin-left: 32px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-0 {
    margin-left: 0;
}

.ml-auto {
    margin-left: auto;
}

.mr-56 {
    margin-right: 56px;
}

.mr-48 {
    margin-right: 48px !important;
}

.mr-40 {
    margin-right: 40px;
}

.mr-32 {
    margin-right: 32px;
}

.mr-24 {
    margin-right: 24px;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-8 {
    margin-right: 8px;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-0 {
    margin-right: 0;
}

.mr-auto {
    margin-right: auto;
}

.mb-96 {
    margin-bottom: 96px;
}

.mb-56 {
    margin-bottom: 48px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mb-40 {
    margin: 0 auto;
    margin-bottom: 48px;
}

.mb-32 {
    margin-bottom: 36px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.pt-96 {
    padding-top: 96px;
}

.pt-56 {
    padding-top: 56px;
}

.pt-48 {
    padding-top: 48px;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-32 {
    padding-top: 32px !important;
}

.pt-24 {
    padding-top: 24px;
}

.pt-12 {
    padding-top: 12px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-0 {
    padding-top: 0 !important; 
}

.pl-96 {
    padding-left: 96px;
}

.pl-56 {
    padding-left: 56px;
}

.pl-48 {
    padding-left: 48px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-32 {
    padding-left: 32px !important;
}

.pl-24 {
    padding-left: 24px;
}

.pl-16 {
    padding-left: 16px !important;
}

.pl-8 {
    padding-left: 8px;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-0 {
    padding-left: 0;
}

.pl-auto {
    padding-left: auto;
}

.pr-56 {
    padding-right: 56px;
}

.pr-48 {
    padding-right: 48px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-32 {
    padding-right: 32px !important;
}

.pr-24 {
    padding-right: 24px;
}

.pr-16 {
    padding-right: 16px !important;
}

.pr-8 {
    padding-right: 8px;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-0 {
    padding-right: 0;
}

.pr-auto {
    padding-right: auto;
}

.pb-96 {
    padding-bottom: 96px;
}

.pb-56 {
    padding-bottom: 48px;
}

.pb-48 {
    padding-bottom: 48px;
}

.pb-40 {
    padding-bottom: 48px;
}

.pb-32 {
    padding-bottom: 36px !important;
}

.pb-24 {
    padding-bottom: 24px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.col-4{
    width: 33.33%;
}

.col-6{
    width: 50%;
}

.col-12 {
    width: 100%;
}


/**
 *RIGHT RESPONSIVE IMAGE By Width
 <div className={`imageWrapper`}>
     <div className={`imageOver`}>
        <img src={src} alt="Right Responsive image" />
     </div>
 </div>
*/

.boxImage{
    height: 385px;
    width: 220px;
    background-color: #252525;
    margin-right: 25px;
    border-radius: 3px;
}
.imageWrapper {
    width: 100%;
    position: relative;
    overflow: hidden !important;
    padding-bottom: 100%;
}

.imageWrapper .imageOver {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    /* bottom: 0;
    top: 0;
    left: 0;
    right: 0; */
}

.imageWrapper .imageOver img {
    width: 220px;
    height: 200px;
    max-width: 100%;
    vertical-align: middle;
    background-color: transparent;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

.boxContent {
    padding: 0px 10px 10px 10px;
}

.boxContent p{
    line-height: 15px;
    font-style: normal;
    margin-top: 5px;
}
/*
    Image to gray
*/

.image-gray {
    -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) !important;
}
